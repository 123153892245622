import { RECRUITMENT } from '../../../api/endpoints';
import request from '../../../api/request';
import { PaginatedRequestType } from '../../../types/pagination';
import { CurrentStep, PostulationInformation } from './types';

export function getPostulations({
  itemsPerPage,
  page,
  offerId,
  steps,
}: {
  itemsPerPage: number;
  page: number;
  offerId: string;
  steps: CurrentStep[];
}) {
  return request<PaginatedRequestType<PostulationInformation>>(
    RECRUITMENT.POSTULATIONS(offerId),
    {
      params: { itemsPerPage, page, steps },
    },
  );
}

export function getBaseInformation(offerId: string | number) {
  return request<any>(RECRUITMENT.BASE_INFORMATION(offerId));
}
