import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQuery, handleSessionExpiredError } from '../../utils/auth';
import { PaginatedRequestType } from '../../types/pagination';
import {
  GetPostulationBaseInformationParams,
  GetPostulatsToAuthorizeParams,
  PostulantToAuthorizeFiltersForm,
  PostulantToAuthorizeItem,
  PostulantToAuthorizePostulationItem,
  UpdatePostulantPostulationsParams,
} from './types';
import { PostulationApplicationStage } from '../recruitment/postulation-review/types';

export const academicAuthorizationApi = createApi({
  reducerPath: 'academicAuthorizationApi',
  baseQuery: getBaseQuery('/mantainer-portal/academic-authorization'),
  endpoints: (builder) => ({
    getPostulantsToAuthorize: builder.query<
      PaginatedRequestType<PostulantToAuthorizeItem>,
      GetPostulatsToAuthorizeParams
    >({
      query: (params) => ({
        url: '/users',
        params: {
          ...params,
          user: params?.name?.trim() || '',
          career: params?.careerId || undefined,
          campus: params?.campusId || undefined,
          authorizationStatus: params?.statusId || undefined,
          offer: params?.offerId || undefined,
          workingDay: params?.workingDayId || undefined,
        },
      }),
      transformErrorResponse: (response) => handleSessionExpiredError(response),
    }),
    getPostulantsToAuthorizePostulations: builder.query<
      PostulantToAuthorizePostulationItem[],
      [string | number, GetPostulatsToAuthorizeParams | undefined]
    >({
      query: ([id, params]) => ({
        url: `/users/${id}/postulations`,
        params: {
          ...params,
          career: params?.careerId || undefined,
          campus: params?.campusId || undefined,
          authorizationStatus: params?.statusId || undefined,
          offer: params?.offerId || undefined,
          workingDay: params?.workingDayId || undefined,
        },
      }),
      transformErrorResponse: (response) => handleSessionExpiredError(response),
    }),
    getFiltersForm: builder.query<PostulantToAuthorizeFiltersForm, void>({
      query: () => '/form',
      transformErrorResponse: (response) => handleSessionExpiredError(response),
    }),
    updatePostulantPostulations: builder.mutation<
      void,
      UpdatePostulantPostulationsParams
    >({
      query: (payload) => ({
        method: 'PUT',
        url: `/users/${payload?.userId}/postulations`,
        body: {
          postulationIds: payload?.postulationIds,
          authorizationStatus: payload?.authorizationStatus,
        },
      }),
      transformErrorResponse: (response) => handleSessionExpiredError(response),
    }),
    getUserBaseInformation: builder.query<any, string | number>({
      query: (id) => `/users/${id}/base-information`,
      transformErrorResponse: (response) => handleSessionExpiredError(response),
    }),
    getPostulationBaseInformation: builder.query<
      any,
      GetPostulationBaseInformationParams
    >({
      query: ({ userId, postulationId }) =>
        `/users/${userId}/postulations/${postulationId}`,
      transformErrorResponse: (response) => handleSessionExpiredError(response),
    }),
    getPostulationStages: builder.query<
      PostulationApplicationStage[],
      GetPostulationBaseInformationParams
    >({
      query: ({ userId, postulationId }) =>
        `/users/${userId}/postulations/${postulationId}/stages`,
      transformErrorResponse: (response) => handleSessionExpiredError(response),
    }),
  }),
});

export const {
  useGetPostulantsToAuthorizeQuery,
  useGetPostulantsToAuthorizePostulationsQuery,
  useGetFiltersFormQuery,
  useUpdatePostulantPostulationsMutation,
  useGetUserBaseInformationQuery,
  useGetPostulationStagesQuery,
  useGetPostulationBaseInformationQuery,
} = academicAuthorizationApi;
