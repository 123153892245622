import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';

// Components
import {
  addToast,
  Button,
  FileInputControlled,
  Modal,
} from '@octano/global-ui';

// Hooks
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useValidations } from '../../../../../hooks/useValidations';

import { ReportType, uploadReport } from '../../api';

export type ModalUploadMethods = {
  open: (reportName: string, reportCode: ReportType) => void;
  close: () => void;
};

type ModalUploadDocumentProps = {
  onConfirm?: () => void;
};

type UploadPayload = {
  file: File | null;
};

const keyPrefix = 'views.settings.reports.modalUpload';

const ModalUpload = (
  { onConfirm }: ModalUploadDocumentProps,
  ref: React.Ref<ModalUploadMethods>,
) => {
  const { t } = useTranslation('translation', { keyPrefix });
  const { validateTextNotEmpty, validateFileSize } = useValidations();
  const [reportName, setReportName] = useState<string>();
  const [reportCode, setReportCode] = useState<ReportType>();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const {
    control,
    watch,
    reset,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<UploadPayload>({
    defaultValues: {
      file: null,
    },
  });

  const [document] = watch(['file']);

  const submitEnabled = useMemo(() => {
    return !!document;
  }, [document]);

  const handleOpen = useCallback(
    (reportName: string, reportCode: ReportType) => {
      setIsOpen(true);
      setReportCode(reportCode);
      setReportName(reportName);
      setValue('file', null);
    },
    [setValue],
  );

  const handleClose = useCallback(() => {
    setIsOpen(false);
    setReportCode(undefined);
    setReportName(undefined);
    reset();
  }, [reset]);

  const handleToggle = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const handleConfirm = async (values: UploadPayload) => {
    if (!values?.file || isSubmitting || !reportCode) {
      return;
    }
    const formData = new FormData();
    formData?.append('file', values?.file);
    const { error } = await uploadReport(reportCode, formData);
    if (error) {
      return addToast({
        icon: 'error',
        color: 'danger',
        text: t('failed'),
      });
    }
    addToast({
      icon: 'check',
      color: 'success',
      text: t('success'),
    });
    handleClose();
    onConfirm && onConfirm();
  };

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose,
  }));

  return (
    <Modal isOpen={isOpen} toggle={handleToggle}>
      <div className="modal-review d-flex w-100 flex-column align-items-center">
        <span className="d-block w-100 text-dark fs-22 lh-30 text-center mb-0">
          {reportName}
        </span>
        <span className="d-block w-100 text-light fs-18 lh-30 text-center mb-4">
          {t('subtitle')}
        </span>

        <div className="w-100 mb-4">
          <FileInputControlled
            label={t('file')}
            name="file"
            btnText={t('upload')}
            control={control}
            accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            rules={{
              validate: {
                notEmpty: validateTextNotEmpty,
                fileSize: validateFileSize,
              },
            }}
          />
        </div>

        <div className="container-fluid px-0 mt-4">
          <div className="row wrap">
            <div className="col-12 col-md-6">
              <Button
                className="w-100 mb-2"
                disabled={isSubmitting}
                onClick={handleClose}
                text={t('cancel')}
                outlined={true}
              />
            </div>
            <div className="col-12 col-md-6">
              <Button
                loading={isSubmitting}
                className="w-100 mb-2"
                onClick={handleSubmit(handleConfirm)}
                text={t('confirm')}
                disabled={!submitEnabled}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default forwardRef(ModalUpload);
