import request from '../../../api/request';

export const Reports = {
  academicPlanning: 'academic-planning',
} as const;

const Actions = {
  base: 'download-base-information',
  template: 'download-template',
  upload: 'upload',
} as const;

export type ReportType = keyof typeof Reports;
export type ActionType = keyof typeof Actions;

const REPORTS_URL = 'mantainer-portal/general-configuration/reports';

export function getReportFiles(reportCode: ReportType, action: ActionType) {
  return request<Blob>(
    `${REPORTS_URL}/${Reports[reportCode]}/${Actions[action]}`,
    {
      responseType: 'blob',
    },
  );
}

export function uploadReport(reportCode: ReportType, formData: FormData) {
  return request(`${REPORTS_URL}/${Reports[reportCode]}/${Actions.upload}`, {
    method: 'POST',
    data: formData,
  });
}
