import { createApi } from '@reduxjs/toolkit/query/react';
import { getBaseQuery, handleSessionExpiredError } from '../../utils/auth';
import {
  GetPublishedOfferseParams,
  PublishedOfferFiltersForm,
  ResumRequest,
} from './types';
import { PaginatedRequestType } from '../../types/pagination';
import { OfferDetail } from '../../types/jobOffer';

export const offersMaintainerApi = createApi({
  reducerPath: 'offersMaintainerApi',
  baseQuery: getBaseQuery(),
  endpoints: (builder) => ({
    getOffersData: builder.query<
      PaginatedRequestType<OfferDetail>,
      GetPublishedOfferseParams
    >({
      query: (params) => ({
        url: '/offers',
        params: {
          ...params,
          career: params?.careerId || undefined,
          campus: params?.campusId || undefined,
          workingDay: params?.workingDayId || undefined,
          name: params?.offerName || undefined,
        },
      }),
      transformErrorResponse: (response) => handleSessionExpiredError(response),
    }),
    getOfferDetailData: builder.query<ResumRequest, string | number>({
      query: (id: string | number) => `/offers/${id}`,
      transformErrorResponse: (response) => handleSessionExpiredError(response),
    }),
    cloneOffer: builder.mutation<ResumRequest, string | number>({
      query: (id: string | number) => ({
        url: `/offer-draft/clone/${id}`,
        method: 'POST',
      }),
      transformErrorResponse: (response) => handleSessionExpiredError(response),
    }),
    getFiltersForm: builder.query<PublishedOfferFiltersForm, void>({
      query: () => '/offers/form',
      transformErrorResponse: (response) => handleSessionExpiredError(response),
    }),
  }),
});

export const {
  useGetOffersDataQuery,
  useGetOfferDetailDataQuery,
  useCloneOfferMutation,
  useGetFiltersFormQuery,
} = offersMaintainerApi;
