import {
  OfferCreationOptions,
  OfferDetail,
  PosibleOfferStateList,
  UpdateOfferStateType,
} from '../../types/jobOffer';
import { Module } from '../../types/modulesTypes';
import { Benefit } from '../../types/offerDetailTypes';
import { SaveDraftFlow, StartDraftFlow } from '../../types/offerDraftFlowTypes';
import { Stage } from '../../types/offerStageTypes';
import { PaginatedRequestType } from '../../types/pagination';
import { CurrentStep } from '../../types/stepsTypes';
import { CREATE_OFFER, CREATION_FLOW, OFFERS } from '../endpoints';
import request from '../request';

export function getCurrentOffers({
  itemsPerPage,
  page,
}: {
  itemsPerPage: number;
  page: number;
}) {
  return request<PaginatedRequestType<OfferDetail>>(OFFERS.CURRENT_OFFERS, {
    params: { itemsPerPage, page },
  });
}

export function getSelectedOfferDetail() {
  return request<OfferCreationOptions>(CREATE_OFFER.DETAIL.GET_FORM_DATA);
}

export function getDraftOffersList({
  itemsPerPage,
  page,
}: {
  itemsPerPage: number;
  page: number;
}) {
  return request<PaginatedRequestType<OfferDetail>>(OFFERS.DRAFT_OFFER_LIST, {
    params: { itemsPerPage, page },
  });
}

export function startOfferDraftFlow() {
  return request<StartDraftFlow>(OFFERS.START_OFFER_DRAFT_FLOW, {
    method: 'POST',
  });
}

export function saveDraftOffer(draftOfferId: number, body: SaveDraftFlow) {
  return request(CREATE_OFFER.DETAIL.SAVE(draftOfferId), {
    method: 'POST',
    data: body,
  });
}

export function saveAndNextDraftOffer(
  draftOfferId: number,
  body: SaveDraftFlow,
) {
  return request(CREATE_OFFER.DETAIL.SAVE_AND_NEXT(draftOfferId), {
    method: 'POST',
    data: body,
  });
}

export function getPosibleOfferStateList() {
  return request<PosibleOfferStateList[]>(OFFERS.POSIBLE_OFFER_STATE_LIST);
}

export function updateOfferState(offerId: number, body: UpdateOfferStateType) {
  return request(OFFERS.UPDATE_OFFER_STATE(offerId), {
    method: 'PUT',
    data: body,
  });
}

export function createBenefit(data: Benefit) {
  return request(CREATE_OFFER.DETAIL.CREATE_BENEFIST, {
    method: 'POST',
    data,
  });
}

export function getSubjects(careerId: number) {
  return request<Module[]>(
    CREATE_OFFER.DETAIL.LIST_SUBJECTS_BY_CAREER(careerId),
  );
}

export function getCareers(schoolId: number) {
  return request<Module[]>(CREATE_OFFER.DETAIL.LIST_CAREER_BY_SCHOOL(schoolId));
}

export function getCurrentStep(offerDraftId: number) {
  return request<CurrentStep>(CREATION_FLOW.GET_FLOW_BY_ID(offerDraftId));
}

/**
 * PETICIONES DEL PASO 2 REQUISITOS
 */

export function getRequirementFormData() {
  return request<any>(CREATE_OFFER.REQUIREMENT.GET_FORM_DATA);
}

export function saveRequirementFormData(draftOfferId: number, body: any) {
  return request(CREATE_OFFER.REQUIREMENT.SAVE(draftOfferId), {
    method: 'POST',
    data: body,
  });
}

export function saveAndNextRequirementFormData(
  draftOfferId: number,
  body: any,
) {
  return request(CREATE_OFFER.REQUIREMENT.SAVE_AND_NEXT(draftOfferId), {
    method: 'POST',
    data: body,
  });
}

export function createEducation(data: { name: string }) {
  return request(CREATE_OFFER.REQUIREMENT.CREATE_EDUCATION, {
    method: 'POST',
    data,
  });
}

export function createCompetence(data: { name: string }) {
  return request(CREATE_OFFER.REQUIREMENT.CREATE_COMPETENCE, {
    method: 'POST',
    data,
  });
}

export function createCertification(data: { name: string }) {
  return request(CREATE_OFFER.REQUIREMENT.CREATE_CERTIFICATION, {
    method: 'POST',
    data,
  });
}

export function createLicenseType(data: { name: string }) {
  return request(CREATE_OFFER.REQUIREMENT.CREATE_LICENSE_TYPE, {
    method: 'POST',
    data,
  });
}

export function createRequirement(data: { name: string }) {
  return request(CREATE_OFFER.REQUIREMENT.CREATE_REQUIREMENT, {
    method: 'POST',
    data,
  });
}

/**
 * PETICIONES DEL PASO 3 PREGUNTAS
 */
export function getQuestionFormData(draftOfferId: number) {
  return request<any>(CREATE_OFFER.QUESTIONS.GET_FORM_DATA(draftOfferId));
}

export function createQuestion(data: { name: string }) {
  return request(CREATE_OFFER.QUESTIONS.CREATE_QUESTION, {
    method: 'POST',
    data,
  });
}

export function createRequest(data: { name: string }) {
  return request(CREATE_OFFER.QUESTIONS.CREATE_REQUIREMENT, {
    method: 'POST',
    data,
  });
}

export function saveQuestionFormData(draftOfferId: number, body: any) {
  return request(CREATE_OFFER.QUESTIONS.SAVE(draftOfferId), {
    method: 'POST',
    data: body,
  });
}

export function saveAndNextQuestionFormData(draftOfferId: number, body: any) {
  return request(CREATE_OFFER.QUESTIONS.SAVE_AND_NEXT(draftOfferId), {
    method: 'POST',
    data: body,
  });
}

/**
 * PETICIONES DEL PASO 4 CREACION DE ETAPAS
 */
export function getStagesFormData(draftOfferId: number) {
  return request<Stage[]>(CREATE_OFFER.STAGES.GET_FORM_DATA(draftOfferId));
}

export function saveStageFormData(draftOfferId: number, body: any) {
  return request(CREATE_OFFER.STAGES.SAVE(draftOfferId), {
    method: 'POST',
    data: body,
  });
}

export function saveAndNextStageFormData(draftOfferId: number, body: any) {
  return request(CREATE_OFFER.STAGES.SAVE_AND_NEXT(draftOfferId), {
    method: 'POST',
    data: body,
  });
}

/**
 * PETICIONES DEL PASO DE REVISION
 */

export function getReviewData(offerDraftFlowId: number) {
  return request<any>(CREATE_OFFER.REVIEW.GET_REVIEW_DATA(offerDraftFlowId));
}

/**
 * Esta función se utiliza para crear una oferta para un ID de flujo de borrador de oferta dado
 * @param {number} offerDraftFlowId - número: esta es la identificación del flujo de borrador de oferta
 * @param {any} body - el cuerpo de la solicitud
 * @returns La respuesta es una promesa que se resuelve en un objeto con las siguientes propiedades:
 */
export function createOffer(offerDraftFlowId: number) {
  return request(CREATE_OFFER.REVIEW.CREATE_OFFER(offerDraftFlowId), {
    method: 'POST',
  });
}
