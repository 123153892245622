export enum UserSessionEvent {
  EXPIRED = 'user-session-expired',
}

export interface Account {
  fullName: string;
  email: string;
}

export interface Permission {
  id: number;
  name_public: string;
  name: PermissionName;
}

export enum PermissionName {
  exampleEdit = 'example.edit',
  exampleDelete = 'example.delete',
  updateAcademicAuthorization = 'update.academic-authorization',
  readAcademicAuthorization = 'read.academic-authorization',
  updateHiring = 'update.hiring',
  readHiring = 'read.hiring',
  createOfferDraftMaintainer = 'create.offer-draft-maintainer',
  updateOfferDraftMaintainer = 'update.offer-draft-maintainer',
  readOfferDraftMaintainer = 'read.offer-draft-maintainer',
  updateOfferMaintainer = 'update.offer-maintainer',
  readOfferMaintainer = 'read.offer-maintainer',
  createRecruitment = 'create.recruitment',
  updateRecruitment = 'update.recruitment',
  readRecruitment = 'read.recruitment',
  createAssignSection = 'create.assign-section',
  updateAssignSection = 'update.assign-section',
  readAssignSection = 'read.assign-section',
  readSettings = 'read.configuration',
  updateSettings = 'update.configuration',
  readReports = 'read.report',
}

export interface UserData extends Account {
  permissions?: Permission['name'][];
}

export interface Account {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  rut: string;
  birthday: string;
  lastLogin: string;
  isActive: boolean;
  roles: any;
  fullName: string;
}

export interface LoginField {
  email: string;
  password: string;
}

export interface AccountValidationFields {
  email: string;
}

export interface UpdateAccountFormField {
  phone: string;
  rut: string;
  password: string;
  passwordConfirm: string;
}

export interface ActivateAccount {
  token: string;
  email: string;
  password: string;
  passwordConfirm: string;
}

export interface AccountRecoveryFields {
  email: string;
}

export interface PasswordRecoveryFormFields {
  password: string;
  passwordConfirm: string;
}

export interface PasswordRecoveryFields {
  token: string;
  email: string;
  password: string;
  passwordConfirm: string;
}

export interface TokenStatus {
  expired: boolean;
}

export interface LoginResponse {
  access: {
    token: string;
  };
  account: Account;
  permissions: Permission[];
}
